import { Helmet } from 'react-helmet';

const SEO = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>nFactorial AI - Data Analyst agent</title>
      <meta name="title" content="" />
      <meta name="description" content="SWE Bench 49%. AI Data Analyst, Autonomous AI agents for your business." />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://nfactorial.dev/" />
      <meta property="og:title" content="Your Product Name - Brief Value Proposition" />
      <meta property="og:description" content="Discover how our product helps you achieve specific benefits. Join the waitlist to be among the first to experience the future of [your industry]." />
      <meta property="og:image" content="https://nfactorial.dev/Data Analyst.png" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://nfactorial.dev/" />
      <meta property="twitter:title" content="Your Product Name - Brief Value Proposition" />
      <meta property="twitter:description" content="Discover how our product helps you achieve specific benefits. Join the waitlist to be among the first to experience the future of [your industry]." />
      <meta property="twitter:image" content="https://nfactorial.dev/Data Analyst.png" />

      {/* Additional SEO Meta Tags */}
      <meta name="keywords" content="AI Data Analyst, AI HR tool, Y combinator startups, Autonomous ai agents" />
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="author" content="nFactorial AI" />
      
      {/* Favicon */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/site.webmanifest" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://nfactorial.dev/" />
    </Helmet>
  );
};

export default SEO;