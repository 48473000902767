function ToolsAndArticleSection() {
    return (
      <>
        {/* Tools Section */}
        <section className="w-full max-w-7xl mx-auto px-4 py-20">
          <h2 className="text-3xl md:text-4xl font-bold text-white text-center mb-6">
            AI-Powered Tools
          </h2>
          <p className="text-gray-300 text-center max-w-3xl mx-auto mb-16 text-lg">
            Transform your workflow with our suite of specialized AI tools
          </p>
          
          <div className="grid md:grid-cols-3 gap-8">
            {/* Investor Tool Card */}
            <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 hover:bg-white/15 transition-all flex flex-col">
              <div className="flex-1">
                <h3 className="text-2xl font-bold text-white mb-4">YC S24 Investor Tool</h3>
                <p className="text-gray-300 mb-6">
                  Comprehensive AI assistant for investors with access to 240+ parsed company profiles.
                </p>
                <ul className="text-gray-300 space-y-2 mb-6">
                  <li>• Quick answers to specific queries</li>
                  <li>• In-depth "pro" answers</li>
                  <li>• Multi-source data integration</li>
                </ul>
              </div>
              <a 
                href="https://research.nfactorial.dev" 
                target="_blank" 
                rel="noopener noreferrer"
                className="block w-full px-6 py-3 bg-black hover:bg-gray-800 text-white font-semibold rounded-lg transition-colors text-center"
              >
                Open Investor Tool
              </a>
            </div>
  
            {/* HR Agent Card */}
            <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 hover:bg-white/15 transition-all flex flex-col">
              <div className="flex-1">
                <h3 className="text-2xl font-bold text-white mb-4">HR Agent</h3>
                <p className="text-gray-300 mb-6">
                  AI-powered assistant for efficient candidate evaluation and HR processes.
                </p>
                <ul className="text-gray-300 space-y-2 mb-6">
                  <li>• Excel & PDF analysis</li>
                  <li>• Candidate evaluation</li>
                  <li>• Custom criteria matching</li>
                </ul>
              </div>
              <a 
                href="https://research.nfactorial.dev" 
                target="_blank" 
                rel="noopener noreferrer"
                className="block w-full px-6 py-3 bg-black hover:bg-gray-800 text-white font-semibold rounded-lg transition-colors text-center"
              >
                Open HR Tool
              </a>
            </div>
  
            {/* Data Analyst Card */}
            <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 hover:bg-white/15 transition-all flex flex-col">
              <div className="flex-1">
                <h3 className="text-2xl font-bold text-white mb-4">Data Analyst</h3>
                <p className="text-gray-300 mb-6">
                  AI-powered data analysis assistant for comprehensive insights and reporting.
                </p>
                <ul className="text-gray-300 space-y-2 mb-6">
                  <li>• Advanced data processing</li>
                  <li>• Automated insights</li>
                  <li>• Custom report generation</li>
                </ul>
              </div>
              <a 
                href="https://research.nfactorial.dev" 
                target="_blank" 
                rel="noopener noreferrer"
                className="block w-full px-6 py-3 bg-black hover:bg-gray-800 text-white font-semibold rounded-lg transition-colors text-center"
              >
                Open Analyst Tool
              </a>
            </div>
          </div>
        </section>
  
        {/* Research Article Section */}
        <section className="w-full max-w-7xl mx-auto px-4 py-20 border-t border-white/10">
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 hover:bg-white/15 transition-all">
            <div className="mx-auto">
              <h2 className="text-3xl font-bold text-white mb-6">SWE-Bench AI Agent: Research Report</h2>
              
              <div className="prose prose-invert max-w-none">
                <p className="text-gray-300 text-lg mb-6">
                  An in-depth analysis of our autonomous benchmark tool designed to evaluate AI models 
                  on software engineering tasks. This research explores the implementation and results 
                  of our SWE-Bench compatible agent.
                </p>
  
                <div className="bg-white/5 rounded-xl p-6 mb-8">
                  <h3 className="text-xl font-semibold text-white mb-4">Key Research Findings</h3>
                  <ul className="text-gray-300 space-y-3">
                    <li>• AI-driven software issue localization and patch generation showing 85% accuracy in test cases</li>
                    <li>• Significant error reduction achieved through structured workflow and brute-force validation</li>
                    <li>• Performance optimization within standard benchmarks demonstrating 40% improvement over baseline</li>
                  </ul>
                </div>
  
                <a 
                  href="/swe-bench" 
                  className="inline-block px-8 py-4 bg-black hover:bg-gray-800 text-white font-semibold rounded-lg transition-colors"
                >
                  Read Full Research Report
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
  
  export default ToolsAndArticleSection