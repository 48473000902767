function WaitlistForm() {
    const handleClick = () => {
      window.open("https://research.nfactorial.dev/", "_blank")
    }
  
    return (
      <div class="w-full space-y-4 mb-8">
        <button
          onClick={handleClick}
          class="w-full bg-[#5f61ff] hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-md transition-all duration-300 ease-in-out focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:outline-none"
        >
          Get Started
        </button>
      </div>
    )
  }
  
  export default WaitlistForm;