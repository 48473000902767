import React from 'react';
import styles from './PrivacyPolicy.module.css';
import Header from './components/Header';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <Header />
      <h1 className={styles.title}>Privacy Policy</h1>
      <p>
        Welcome to <strong>nFactorial Inc</strong>. Your privacy is important to
        us, and we are committed to safeguarding the information you share with
        us through our platform, <strong>research.nfactorial.dev</strong>. This
        Privacy Policy explains how we collect, use, and protect your
        information.
      </p>

      <h2 className={styles.sectionTitle}>I. Information We Collect</h2>
      <p>
        We collect the following types of information to provide and improve our
        services:
      </p>
      <ul className={styles.list}>
        <li>
          <strong>Personal Information:</strong> This includes your email
          address and data you upload to{' '}
          <strong>research.nfactorial.dev</strong>, such as files and links.
          This information is used for account management and service
          functionality.
        </li>
        <li>
          <strong>Non-Personal Information:</strong> This includes browser
          details, device identifiers, IP addresses, usage statistics, and
          cookies. This information helps us analyze trends and improve user
          experience.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you interact with
          our platform, including session durations, error reports, and
          preferences.
        </li>
        <li>
          <strong>Google User Data:</strong> When you connect your Google Drive
          account, we request access to all files in your Google Drive. This includes,
          but is not limited to, documents, spreadsheets, presentations, PDFs, images,
          and other file types stored in your Google Drive. We process these files to
          enable our chat interface functionality or do analysis, allowing you to interact with and
          query your data. While we need access to read these files to provide our
          service, we do not permanently store the content of your files on our servers.
          All processing is done securely and temporarily for the sole purpose of
          enabling the chat functionality. We do not modify, delete, or share your
          Google Drive files without your explicit permission.
        </li>
      </ul>

      <h2 className={styles.sectionTitle}>II. How We Use Your Information</h2>
      <p>The information we collect is used to:</p>
      <ul className={styles.list}>
        <li>Provide and improve our services</li>
        <li>Facilitate communication and respond to your inquiries</li>
        <li>Analyze platform usage and monitor system performance</li>
        <li>Send promotional and service-related communications</li>
        <li>Comply with legal requirements and enforce our policies</li>
        <p>
  <strong>Note:</strong> Data submitted by users, including uploaded files
  and Google Drive documents, is used solely for providing the service.
  We do not use user data for AI model training.
</p>

      </ul>

      <h2 className={styles.sectionTitle}>III. Sharing Your Information</h2>
      <p>
        We do not sell or trade your personal information to third parties.
        However, we may share your information with:
      </p>
      <ul className={styles.list}>
        <li>
          <strong>Service Providers:</strong> Vendors who help us operate our
          platform, such as cloud hosting providers, email delivery services,
          and analytics tools.
        </li>
        <li>
          <strong>Legal Compliance:</strong> Authorities if required to comply
          with a legal obligation, protect against fraud, or enforce our terms
          of service.
        </li>
      </ul>

      <h2 className={styles.sectionTitle}>
        IV. Cookies and Tracking Technologies
      </h2>
      <p>
        We use cookies and similar tracking technologies to enhance your
        experience. These technologies help us:
      </p>
      <ul className={styles.list}>
        <li>Recognize your preferences and settings</li>
        <li>Analyze usage patterns</li>
        <li>Deliver targeted advertisements and content</li>
      </ul>
      <p>
        You can manage cookie preferences through your browser settings. Note
        that disabling cookies may affect your experience on our platform.
      </p>

      <h2 className={styles.sectionTitle}>V. Your Rights</h2>
      <p>
        As a user, you have the following rights regarding your personal
        information:
      </p>
      <ul className={styles.list}>
        <li>
          <strong>Access:</strong> Request a copy of the personal data we hold
          about you.
        </li>
        <li>
          <strong>Correction:</strong> Update or correct inaccurate or
          incomplete information.
        </li>
        <li>
          <strong>Deletion:</strong> Request deletion of your data, subject to
          legal obligations.
        </li>
        <li>
          <strong>Opt-Out:</strong> Unsubscribe from promotional communications
          by following the link in our emails or contacting{' '}
          <strong>support@nfactorial.dev</strong>.
        </li>
      </ul>

      <h2 className={styles.sectionTitle}>VI. Children's Privacy</h2>
      <p>
        Our platform is not intended for children under the age of 13. If we
        discover that we have collected data from a child under 13, we will
        delete it immediately. For assistance, contact us at{' '}
        <strong>support@nfactorial.dev</strong>.
      </p>

      <h2 className={styles.sectionTitle}>VII. Data Security</h2>
      <p>
        We use industry-standard measures, including encryption and firewalls,
        to protect your information. While we strive to secure your data, no
        system can guarantee absolute security. By using our platform, you
        acknowledge and accept these risks.
      </p>

      <h2 className={styles.sectionTitle}>VIII. Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Changes will be posted
        on this page, and significant updates will be communicated via email. We
        encourage you to review this policy regularly.
      </p>

      <h2 className={styles.sectionTitle}>IX. Contact Us</h2>
      <p>
        If you have questions or concerns about this Privacy Policy, please
        contact us at <strong>support@nfactorial.dev</strong>.
      </p>
      <p className={styles.lastUpdated}>
        Last Updated: {new Date().toLocaleDateString()}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
