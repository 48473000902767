// import { XIcon } from "./icons/x-icon"
import { InstagramIcon } from "./icons/instagram-icon"
import { LinkedInIcon } from "./icons/linkedin-icon"
import SocialIcon from "./social-icon"
import WaitlistForm from "./waitlist-form"

function WaitlistSignup() {
  return (
    <div class="w-full max-w-3xl mx-auto p-8 flex flex-col justify-between min-h-screen">
      <div class="flex-1 flex flex-col justify-center items-center text-center">
        <div className="m-4">
          <h1 class="text-4xl sm:text-5xl font-extrabold m-4 bg-clip-text text-transparent bg-gradient-to-br from-gray-200 to-gray-600 ">
            We're building an<br />AI Data Analyst 
          </h1>
        </div>
        <div>
          <p class="text-lg sm:text-xl mb-8 text-gray-300">
            Our mission is to enable the next generation of $1B software companies with only 1 employee. We want to provide AI workers for these companies. AI data analyst is just a first step.
          </p>
        </div>
        <div class="w-full">
          <WaitlistForm />
        </div>
      </div>
      <div class="pt-8 flex justify-center space-x-6">
        {/* <SocialIcon
          href="https://x.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="X (formerly Twitter)"
        >
          <XIcon class="w-6 h-6" />
        </SocialIcon> */}
        <SocialIcon
          href="https://www.instagram.com/nfactorial.ai/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <InstagramIcon class="w-6 h-6" />
        </SocialIcon>
        {/* <SocialIcon
          href="https://discord.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Discord"
        >
          <DiscordIcon class="w-6 h-6" />
        </SocialIcon> */}
        {/* <SocialIcon
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FacebookIcon class="w-6 h-6" />
        </SocialIcon> */}
        <SocialIcon
          href="https://www.linkedin.com/company/nfactorial-ai"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="LinkedIn"
        >
          <LinkedInIcon class="w-6 h-6" />
        </SocialIcon>
      </div>
    </div>
  )
}

export default WaitlistSignup;