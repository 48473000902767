import React from 'react';
import styles from './TermsOfService.module.css';
import Header from './components/Header';

const TermsOfService = () => {
  return (
    <div className={styles.container}>
      <Header />
      <h1 className={styles.title}>Terms of Service</h1>
      <p>
        Welcome to <strong>nFactorial Inc</strong>. These Terms of Service
        ("Terms") govern your use of our platform,{' '}
        <strong>research.nfactorial.dev</strong>, which enables you to interact
        with your files and links using AI technology. By accessing or using our
        service, you agree to these Terms. If you do not agree, please do not
        use our platform.
      </p>

      <h2 className={styles.sectionTitle}>1. Acceptance of Terms</h2>
      <p>
        By accessing or using <strong>research.nfactorial.dev</strong>, you
        confirm that you are at least 18 years old or have the legal capacity to
        enter into this agreement. You agree to be bound by these Terms and our
        Privacy Policy.
      </p>

      <h2 className={styles.sectionTitle}>2. Services Provided</h2>
      <p>
        <strong>research.nfactorial.dev</strong> allows users to upload files or
        provide links to interact with them using AI technology. We process
        user-provided data to enable these interactions but do not sell or share
        this data with third parties. Personal data collected includes only your
        email address, which is used to manage your account and communicate
        service-related updates.
      </p>

      <h2 className={styles.sectionTitle}>3. User Responsibilities</h2>
      <p>As a user of our platform, you agree to the following:</p>
      <ul className={styles.list}>
        <li>
          Provide accurate and current information when creating your account
          and using our services.
        </li>
        <li>
          Use the platform only for lawful purposes and refrain from uploading
          content that is illegal, harmful, or violates the rights of others.
        </li>
        <li>
          Do not attempt to gain unauthorized access to our platform, servers,
          or data.
        </li>
        <li>
          Retain backups of your uploaded files, as we are not liable for data
          loss.
        </li>
      </ul>

      <h2 className={styles.sectionTitle}>4. Data Usage</h2>
      <p>
        We process user-uploaded files and links to enable AI-driven
        interactions. Uploaded data is not stored permanently on our systems and
        is only used for providing the service. We do not sell your data or use
        it for purposes other than outlined in our Privacy Policy.
      </p>
      <p>
        Your email address is stored securely and used solely for account
        management, service notifications, and customer support. We do not store
        your passwords.
      </p>

      <h2 className={styles.sectionTitle}>5. Prohibited Activities</h2>
      <p>You are prohibited from engaging in the following activities:</p>
      <ul className={styles.list}>
        <li>
          Uploading content that contains malware, viruses, or harmful code.
        </li>
        <li>
          Using the platform to engage in illegal activities or violate
          intellectual property rights.
        </li>
        <li>
          Attempting to reverse-engineer, replicate, or exploit our platform's
          technology.
        </li>
        <li>Sharing your account credentials with unauthorized users.</li>
      </ul>

      <h2 className={styles.sectionTitle}>6. Intellectual Property</h2>
      <p>
        All intellectual property rights related to the platform, including the
        AI technology, are owned by <strong>nFactorial Inc</strong>. You retain
        ownership of the files and links you upload. By using our platform, you
        grant us a limited license to process and interact with your files for
        the sole purpose of providing the service.
      </p>

      <h2 className={styles.sectionTitle}>7. Limitation of Liability</h2>
      <p>
        <strong>nFactorial Inc</strong> is not responsible for:
      </p>
      <ul className={styles.list}>
        <li>
          Loss of data resulting from user error, technical issues, or
          unauthorized access.
        </li>
        <li>
          Any indirect, incidental, or consequential damages arising from the
          use of our platform.
        </li>
        <li>
          Content uploaded by users that may infringe on the rights of others.
        </li>
      </ul>

      <h2 className={styles.sectionTitle}>8. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your account at any time
        for violations of these Terms, illegal activities, or if required by
        law.
      </p>

      <h2 className={styles.sectionTitle}>9. Modifications to Terms</h2>
      <p>
        We may update these Terms periodically. Changes will be posted on this
        page, and significant updates will be communicated via email. Your
        continued use of the platform constitutes acceptance of the updated
        Terms.
      </p>

      <h2 className={styles.sectionTitle}>10. Governing Law</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of
        the jurisdiction where <strong>nFactorial Inc</strong> is registered.
        Any disputes arising from these Terms will be resolved in that
        jurisdiction's courts.
      </p>

      <h2 className={styles.sectionTitle}>11. Contact Us</h2>
      <p>
        If you have questions or concerns about these Terms, please contact us
        at <strong>support@nfactorial.dev</strong>.
      </p>
      <p className={styles.lastUpdated}>
        Last Updated: {new Date().toLocaleDateString()}
      </p>
    </div>
  );
};

export default TermsOfService;
