function SocialIcon({ href, target, rel, "aria-label": ariaLabel, children }) {
    return (
      <a
        href={href}
        target={target}
        rel={rel}
        aria-label={ariaLabel}
        class="text-gray-400 hover:text-gray-300 transition-colors duration-200"
      >
        {children}
      </a>
    );
  }
  
  export default SocialIcon;