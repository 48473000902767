import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import ToolsSection from "./components/ToolsSection";
import WaitlistSignup from "./components/waitlist-signup";
import SEO from "./components/SEO";
import './App.css';

const styles = {
  main: {
    background: "radial-gradient(circle at center, #1E40AF, #000000)"
  },
  pattern: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `
      linear-gradient(to right, rgba(255,255,255,0.02) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(255,255,255,0.02) 1px, transparent 1px)
    `,
    backgroundSize: "20px 20px",
    pointerEvents: "none",
    zIndex: 1
  },
  content: {
    position: "relative",
    zIndex: 2
  }
};

function LandingPage() {
  return (
    <>
      <SEO />
      <main
        className="min-h-screen flex items-center justify-center"
        style={styles.main}
      >
        <div>
          <Header />
        </div>
        <div style={styles.pattern}></div>
        <div className="w-full" style={styles.content}>
          <WaitlistSignup />
          <ToolsSection />
        </div>
        <Footer />
      </main>
    </>
  );
}

export default LandingPage;