import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="mt-auto py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col items-center space-y-4 text-gray-300 text-sm">
          <div>
            Powered by <span className="font-bold">nFactorial AI</span>
          </div>
          <div className="flex space-x-6">
            <Link 
              to="/privacy-policy" 
              className="hover:text-white transition-colors"
            >
              Privacy Policy
            </Link>
            <Link 
              to="/terms-of-service" 
              className="hover:text-white transition-colors"
            >
              Terms of Service
            </Link>
          </div>
          <div className="text-gray-500">
            nFactorial, Inc.
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;