import { useState } from 'react';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="fixed top-6 left-0 right-0 z-50 mx-4">
      <div className="mx-auto max-w-7xl bg-gray-900/50 backdrop-blur-lg rounded-2xl">
        <nav className="flex items-center justify-between px-6 py-4">
          {/* Logo */}
          <a href="/" className="flex items-center space-x-2">
            <img src="/logo400.png" alt="Logo" className="h-8 w-8" />
            <span className="text-white font-bold text-xl">nFactorial AI</span>
          </a>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <a href="/swe-bench" className="text-white hover:text-gray-200 transition-colors">SWE-Bench</a>
            <div className="flex items-center space-x-4">
              <a href="https://research.nfactorial.dev/sign-in" className="text-white hover:text-gray-200 transition-colors">Sign in</a>
              <a href="https://research.nfactorial.dev/sign-up" className="px-4 py-2 text-white bg-black rounded-lg hover:bg-gray-800 transition-colors">Sign up</a>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <button className="md:hidden text-white p-2" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>
        </nav>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="md:hidden px-4 py-3 bg-gray-900/50 backdrop-blur-lg border-t border-white/10 rounded-b-lg">
            <div className="flex flex-col space-y-4">
              <a href="https://research.nfactorial.dev/" className="text-white hover:text-gray-200 transition-colors">HR agent</a>
              <a href="https://research.nfactorial.dev/" className="text-white hover:text-gray-200 transition-colors">Data Analyst agent</a>
              <a href="https://research.nfactorial.dev/" className="text-white hover:text-gray-200 transition-colors">YC investor tool</a>
              <a href="https://research.nfactorial.dev/" className="text-white hover:text-gray-200 transition-colors">SWE-bench</a>
              <hr className="border-white/10" />
              <a href="https://research.nfactorial.dev/sign-in" className="text-white hover:text-gray-200 transition-colors">Sign in</a>
              <a href="https://research.nfactorial.dev/sign-up" className="px-4 py-2 text-white bg-black rounded-lg hover:bg-gray-800 transition-colors text-center">Sign up</a>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
